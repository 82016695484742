import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class Recruitment extends React.Component {
	render() {
		const siteTitle = 'HR Consulting'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<Container className="py-5">
					<Row>
						<Col id="recruitment" className="my-4">
							<h5>
								Full Service Employment Engagement; From Hire to exit and
								everything in between.
							</h5>
							<p className="my-3">
								EMPLOYEE FILES - Did you know you need to keep not just one but
								three files on each of your employees? You need to have an I-9
								file, a benefits file and an employment file for each member of
								your team. If you don't fill out and keep these forms, files and
								information accurate, securely, and in the right place, you
								could face fines and penalties, sometimes up to several thousand
								dollars. Call us if you have questions or unsure about your
								files.
							</p>
							<p className="my-3">
								Do you have an employee handbook? If not, you should. Even if
								you only have a few employees, you still need a manual or
								handbook to lay out the rules, regulations and expectations you
								have for your employees. Handbooks make it easier for employees
								to know exactly what's expected of them, but they can also be
								used to cover your back in case of employee disputes. It's also
								important to note that if the handbook is done incorrectly, it
								can do more damage than good, which is why having a professional
								work with you to ensure all rist is mitigated with how you want
								to run your business.
							</p>
							<p>Independent Contractor Review - Mitigate your Risk.</p>
							<Row className="py-2">
								<Col>
									<ul className="list-unstyled checked">
										<li>
											<i className="fa fa-check mr-2" />
											Independent Contractor Vetting/Qualification
										</li>
										<li>
											<i className="fa fa-check mr-2" />
											Invoice & payment
										</li>
									</ul>
								</Col>
								<Col>
									<ul className="list-unstyled checked">
										<li>
											<i className="fa fa-check mr-2" />
											File Management/Defense Documents
										</li>
										<li>
											<i className="fa fa-check mr-2" />
											Statement of Work/Contract Management
										</li>
									</ul>
								</Col>
							</Row>
							<Row className="py-2">
								<Col>
									<p>Performance Reviews, do you have them in place?</p>
									<p>
										Growth Plan, do you know who you will need and where to get
										them?
									</p>
								</Col>
								<Col>
									<p>
										Whether or hiring a team or want to take care of the one you
										have, we are here to help.
									</p>
									<p>
										Does your growing business really need HR? Absolutely. Here
										are a few reasons why - and a few ways you can implement it
										while still providing cost savings.
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Layout>
		)
	}
}

export default Recruitment

export const RecruitmentPageQuery = graphql`
	query RecruitmentPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
